import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import tw from 'twin.macro';

export default ({
  fluid,
  title,
  subtitle,
  size = 'large',
  cssProps,
  children,
}) => (
  <BackgroundImage
    css={[
      size === 'medium' ? tw`h-48 sm:h-64` : tw`h-screen`,
      // tw`bg-center bg-cover`,
    ]}
    fluid={fluid}
    Tag="section"
    style={{
      // Defaults are overwrite-able by setting one or each of the following:
      backgroundPosition: '50% 75%',
    }}
  >
    <div
      css={[
        tw`flex flex-col items-center justify-center h-full font-serif text-center sm:px-0`,
        cssProps,
      ]}
    >
      <h1
        css={tw`text-5xl font-medium tracking-wide text-brand-orange sm:text-8xl`}
      >
        {title}
      </h1>
      {title && <hr css={tw`w-10/12 my-2 sm:w-8/12`} />}
      <h2
        css={tw`text-lg text-white sm:text-2xl`}
        className="subtitle has-text-white is-size-4 is-size-6-mobile"
      >
        {subtitle}
      </h2>
      {children}
    </div>
  </BackgroundImage>
);
